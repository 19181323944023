import { columns } from "./config/index";
import { IMApi } from "@/api";
export default {
  data: function data() {
    return {
      columns: columns,
      page: 1,
      size: 10,
      total: 0,
      dataSource: [{}],
      loading: false,
      visibleDel: false,
      delItem: {},
      visibleDetail: false,
      detailForm: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleButton: function handleButton(Type, item) {
      switch (Type) {
        case 'view':
          this.visibleDetail = true;
          this.detailForm = item;
          break;
        case 'fail':
          break;
        case 'del':
          this.visibleDel = true;
          this.delItem = item;
          break;
        default:
          break;
      }
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    getList: function getList() {},
    comfirmDel: function comfirmDel() {}
  }
};