var columns = [{
  title: '内容id',
  dataIndex: 'id',
  key: 'id',
  width: '18%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '用户名',
  dataIndex: 'name',
  key: 'name',
  width: '22%',
  scopedSlots: {
    customRender: 'name'
  },
  align: 'center'
}, {
  title: '审核来源',
  dataIndex: 'source',
  key: 'source',
  width: '10%',
  scopedSlots: {
    customRender: 'source'
  },
  align: 'center'
}, {
  title: '类型',
  dataIndex: 'type',
  key: 'type',
  width: '18%',
  scopedSlots: {
    customRender: 'type'
  },
  align: 'center'
}, {
  title: '举报类型',
  dataIndex: 'num',
  key: 'num',
  width: '10%',
  scopedSlots: {
    customRender: 'num'
  },
  align: 'center'
}, {
  title: '发布时间(UTC)',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '18%',
  scopedSlots: {
    customRender: 'createTime'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: 'right',
  width: 240,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns };