var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-reports"
  }, [_c("a-card", {
    attrs: {
      borderd: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")]), _c("a", {
          staticStyle: {
            margin: "0 20px"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("fail", row);
            }
          }
        }, [_vm._v("不予通过")]), _c("a", {
          staticStyle: {
            color: "#ff4d4f"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("del", row);
            }
          }
        }, [_vm._v("删除")])])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "查看详情",
      width: 680
    },
    on: {
      ok: function ok($event) {
        _vm.visibleDetail = false;
      }
    },
    model: {
      value: _vm.visibleDetail,
      callback: function callback($$v) {
        _vm.visibleDetail = $$v;
      },
      expression: "visibleDetail"
    }
  }, [_c("a-form-model", {
    ref: "detailForm",
    attrs: {
      model: _vm.detailForm,
      layout: "inline"
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "User iD",
      prop: "userid"
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.detailForm.userid))])], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "删除确认"
    },
    on: {
      ok: _vm.comfirmDel
    },
    model: {
      value: _vm.visibleDel,
      callback: function callback($$v) {
        _vm.visibleDel = $$v;
      },
      expression: "visibleDel"
    }
  }, [_c("p", [_vm._v("确认删除内容" + _vm._s() + "吗？")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };